import RadarChart from '../../../shared/radar_chart';

export default RadarChart.extend({
  template: false,

  ui: {
    canvas: 'canvas',
    panel : '.panel'
  },

  chartOptions: {
    layout: {
      padding: 5
    },
    scale: {
      ticks: {
        max     : 4,
        min     : 0,
        stepSize: 1
      }
    }
  }
});
