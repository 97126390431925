import { View } from 'backbone.marionette';
import $ from 'jquery';
import _ from 'underscore';
import d3 from 'd3';
import nv from 'nvd3';
import Utils from '../../../../utils';
import tooltipTmpl from '../../../templates/student/achieves/stack_tip.pug';

export default View.extend({
  el      : '.js-achieve-process-chart',
  template: false,
  t       : Utils.I18n.bind('views.student.achieves.process_chart'),

  ui: {
    chart: 'svg'
  },

  // 静的なチャート設定
  chartConfig: {
    stacked     : true,
    showControls: false
  },

  load(mapId) {
    if (!this.$el.data('url')) return;

    this.$el.block();
    $.get(this.$el.data('url').replace('/:id/', `/${mapId}/`))
     .done(_.bind(this.renderChart, this))
     .always(() => {
       this.$el.unblock();
     });
  },

  renderChart(response) {
    this.setPrintWidth();
    this.setAxisNames(response);

    const isShowLegend = this.$el.data('legend') !== false;
    const top = isShowLegend ? 0 : 10;

    nv.addGraph(() => {
      const chart = nv.models.multiBarChart()
                             .reduceXTicks(false)
                             .options(this.chartConfig)
                             .showLegend(isShowLegend)
                             .forceY([0, response.gpt_y_axis])
                             .noData(this.t('.noData'))
                             .margin({ top: top, left: 40, right: 15, bottom: 20 });

      chart.tooltip.contentGenerator(_.bind(this.tooltip, this));

      d3.select(this.ui.chart.get(0))
        .datum(response.data)
        .call(chart);

      this.drawRequireLine(chart, response.gpt_goal_point);

      // 幅変更で書き直す必要があるものはリサイズイベントに登録する
      nv.utils.windowResize(() => {
        chart.update();
        this.drawRequireLine(chart, response.gpt_goal_point);
        this.setPrintWidth();
      });

      return chart;
    });
  },

  // SVG描画は印刷時のリサイズで連動しないため幅が不足しチャートが途切れることがある
  // それを避けるため描画時の幅を印刷時にも確保すべくstyleに書き込む
  setPrintWidth() {
    this.$el.width('');
    this.$el.width(this.$el.width());
  },

  // 通信結果から軸名を反映させるため保持する
  setAxisNames(response) {
    this.axisNames = _.mapObject(_.indexBy(response.data, 'key'), (datum) => {
      return datum.name;
    });
  },

  tooltip(obj) {
    if (!obj) return '';
    return tooltipTmpl({
      x    : obj.data.x,
      y    : obj.data.y,
      color: obj.color,
      key  : obj.data.key,
      name : this.axisNames[obj.data.key]
    });
  },

  // 目標値で線を引くという実装では座標指定が大変なので
  // 「目標だけのY軸を右に配置する」という方法で実装する
  // ※）右側のY軸がある nvd3.multiChart.jsを実装を参考にした
  drawRequireLine(chart, gptGoalPoint) {
    const margin = chart._options.margin;
    const container = d3.select(this.ui.chart.get(0));
    const availableWidth = nv.utils.availableWidth(null, container, margin);
    const gWrap = container.select('g.nv-wrap > g');

    const axis = nv.models.axis()
                   .orient('right')
                   .scale(chart.yScale())
                   .showMaxMin(false)
                   .rotateYLabel(false)
                   .tickValues([gptGoalPoint])
                   .tickSize(-availableWidth, 0)
                   .tickFormat(() => { return this.t('.requireMark'); });

    gWrap.select('g.require-line').remove();
    gWrap.append('g')
         .attr('class', 'require-line nv-axis')
         .attr('transform', `translate(${availableWidth}, 0)`)
         .call(axis);
  }
});
