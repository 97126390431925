import { View } from 'backbone.marionette';
import ShowView from './show';
import AptitudeView from './aptitude';
import ProcessChart from './process_chart';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    selectpick: '.selectpicker',
    mapList   : 'select.js-map'
  },

  events: {
    'change @ui.mapList': 'changeMap'
  },

  onRender() {
    this.ui.selectpick.selectpicker();
    this.showView = (new ShowView()).render();
    this.aptitudeView = (new AptitudeView()).render();
    this.processChart = (new ProcessChart()).render();

    if (this.ui.mapList.val()) {
      this.changeMap();
    }
  },

  // レーダーチャート: マップの切り替えではシート数が異なるためhtmlごと再描画する
  //      GPT達成度: レンダリング出力なので常に再描画
  changeMap() {
    const mapId = this.ui.mapList.val();
    this.showView.load(mapId);
    this.aptitudeView.load(mapId);
    this.processChart.load(mapId);
  }
});
