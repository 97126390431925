import $ from 'jquery';
import _ from 'underscore';
import RadarChart from '../../shared/radar_chart';

export default RadarChart.extend({
  template: false,

  ui: {
    canvas: 'canvas',
    panel : '.panel'
  },

  chartOptions: {
    responsive: true,
    legend    : {
      position: 'right'
    },
    layout: {
      padding: 5
    },
    scale: {
      ticks: {
        max     : 4,
        min     : 0,
        stepSize: 1
      }
    }
  },

  // マップIDを条件にチャートデータを読み込む
  load(data) {
    if (!this.$el.data('url')) return;

    const $panel = this.ui.panel;

    $panel.block();
    $.get(this.$el.data('url'), data)
     .done(_.bind(this.update, this))
     .always(() => {
       $panel.unblock();
     });
  },

  // 設定や状態を反映させつつチャートを更新
  update(data) {
    _.each(data.datasets, (dataset, i) => {
      _.extend(dataset, this.colors[i]);
    });
    this.changeMaxScale(data);
    this.renderChart(data);
  }
});
