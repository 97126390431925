import _ from 'underscore';
import { View }  from 'backbone.marionette';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.mentor.generic_evaluations'),

  ui: {
    inputField   : 'input',
    tooltipTitles: '#js-tooltip-titles',
    commentLink  : 'a.js-comment'
  },

  events: {
    'change @ui.inputField'               : 'onChange',
    'focusin @ui.inputField[type=number]' : 'onRatyFocusIn',
    'focusout @ui.inputField[type=number]': 'onRatyFocusOut',
    'click @ui.commentLink'               : 'onClickCommentLink'
  },

  onRender() {
    _.each(this.ui.commentLink, (el) => {
      this.switchIcon(this.$(el));
    });
  },

  onChange(e) {
    const $field = this.$(e.currentTarget);
    const firstValue = $field.data('value') || '';
    const currentValue = $field.val() || '';
    const $td = $field.parents('td');

    if (firstValue !== currentValue) {
      $td.addClass('dirty');
    } else {
      $td.removeClass('dirty');
    }
  },

  // 入力補助の情報表示をツールチップを流用して実現する
  onRatyFocusIn(e) {
    const $field = this.$(e.currentTarget);
    const itemID = $field.data('item-id');
    const studentRaty = $field.data('student-raty');
    const tooltipTemplate = this.ui.tooltipTitles.find(`.js-tooltip-item-${itemID}`).html();

    $field.tooltip({
      html     : true,
      placement: 'auto',
      title    : tooltipTemplate.replace(':studentRaty:', studentRaty),
      whiteList: Utils.TooltipWhiteList.setTable({ th: ['colspan'], td: ['class', 'colspan'] })
    }).tooltip('enable').tooltip('show');
  },

  // ツールチップであるので作ったあとはマウスオーバーでも反応してしまう
  // フォーカスが対象のみで動かしたいのでフォーカスが外れる際に無効化しておく
  onRatyFocusOut(e) {
    const $field = this.$(e.currentTarget);

    $field.tooltip('disable').tooltip('hide');
  },

  onClickCommentLink(e) {
    const $link = this.$(e.currentTarget);
    const $input = $link.siblings('input');
    const $otherComments = $link.siblings('.js-other-comments');

    Utils.Modal.prompt(
      `${$otherComments.html()}${this.t('.comment_info')}`,
      (message) => {
        if (message == null) return;
        this.onOkComment(message, $link);
      },
      {
        title      : this.t('.comment_title', { target: $link.data('target') }),
        defaultText: $input.val(),
        counter    : 2000
      }
    );
  },

  onOkComment(message, $link) {
    $link.siblings('input').val(message).trigger('change');
    this.switchIcon($link);
  },

  switchIcon($link) {
    const hasMyComment = !!$link.siblings('input').val();
    const hasOtherComments = !!$link.siblings('.js-other-comments').html();
    const $icon = $link.find('i');

    $icon.removeClass('fa-comment fa-comment-o fa-comments fa-comments-o');

    switch ([hasMyComment, hasOtherComments].toString()) {
      case [true, true].toString():
        $icon.addClass('fa-comments');
        break;
      case [false, true].toString():
        $icon.addClass('fa-comments-o');
        break;
      case [true, false].toString():
        $icon.addClass('fa-comment');
        break;
      case [false, false].toString():
        $icon.addClass('fa-comment-o');
        break;
      default:
    }
  }
});
