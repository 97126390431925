import { View } from 'backbone.marionette';
import EditRubrics from '../../_layouts/edit_rubrics';
import EditRating  from '../../_layouts/edit_rating';
import LicenseIcons from '../../_layouts/license_icons';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    ratingFields: '.js-rating-field',
    rubricFields: '.js-rubric-field'
  },

  onRender() {
    this.renderRubrics();
    this.buildRating();
    new LicenseIcons().render();
  },

  renderRubrics() {
    this.ui.rubricFields.each((_index, el) => {
      (new EditRubrics({ el: `#${el.id}` })).render();
    });
  },

  buildRating() {
    this.ui.ratingFields.each((_index, el) => {
      (new EditRating({ el: `#${el.id}` })).render();
    });
  }
});
