import { View } from 'backbone.marionette';
import jade from 'jade';
import Record from '../../../models/html';

export default View.extend({
  el      : '.asm-achieve-rate',
  template: jade.compile('!= html'),

  model      : new Record(),
  modelEvents: {
    change: 'render',
    sync  : 'onSync'
  },

  load(mapId) {
    if (!this.$el.data('url')) return;

    this.$el.block();
    this.model.fetch({
      url: this.$el.data('url').replace('/:id/', `/${mapId}/`)
    });
  },

  onSync() {
    this.$el.unblock();
  }
});
