import { View } from 'backbone.marionette';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';
import ShowRating from '../../_layouts/show_rating';
import LicenseIcons from '../../_layouts/license_icons';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [Behaviors.CommentPhrase],

  ui: {
    ratingFields: '.js-rating-field',
    licenseIcons: '.js-asm-license-icon'
  },

  onRender() {
    this.buildRating();
    new LicenseIcons().render();
    Utils.Opener.set('LrSkillAnswerDecoratorComments', this);
  },

  buildRating() {
    this.ui.ratingFields.each((_index, el) => {
      new ShowRating({ el: `#${el.id}` }).render();
    });
  }
});
