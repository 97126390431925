import $ from 'jquery';
import { View } from 'backbone.marionette';
import d3 from 'd3';

export default View.extend({
  el: '.js-asm-license-icon',

  template: false,

  onRender() {
    this.paintIcons();
  },

  paintIcons() {
    const colors = d3.scale.category20().range();

    this.$el.each((_, element) => {
      const index = Number(element.dataset.licenseIndex);
      const color = colors[index % colors.length];
      $(element).css('color', color);
    });
  }
});
